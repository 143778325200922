@import 'styles/_sass-includes.scss';

.variantselector__grid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;

  &.product {
    width: calc(100% + 10px);

    .variantselector__item {
      width: calc(33.33% - 10px);
      // media queries
      @include breakpoint($mobile-md) {
        width: calc(50% - 10px);
      }
    }
  }

  &.size {
    display: grid;
    grid-template-columns: repeat(6, minmax(16.666667%, max-content));
    border-top: 1px solid var(--neutral-20);
    border-left: 1px solid var(--neutral-20);
    border-radius: 8px;
    overflow: hidden;

    .variantselector__item {
      margin: 0;
      border: 0;
      width: auto;
      white-space: nowrap;
      width: 100%;
      border: 0;
      border-right: 1px solid var(--neutral-20);
      border-bottom: 1px solid var(--neutral-20);
      overflow: hidden;
    }

    &.underfill {
      border-top: none;

      .variantselector__item {
        border-top: 1px solid var(--neutral-20);

        &:first-of-type {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        &:last-of-type {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }
  }
}

.variantselector__item {
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  border: 1px solid transparent;

  &.is-disabled {
    opacity: 0.4;
  }

  &.is-active {
    .variantselector__color {

      &:after {
        border-color: #000;
        border-width: 2px;
        top: -3px;
        left: -3px;
      }
    }

    .variantselector__size {
      color: $white;
    }

    .variantselector__product .variantselector__product__img {
      border-color: #000;
    }
  }

  input {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.variantselector__color {
  width: 25px;
  height: 25px;
  display: block;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  margin-right: 3px;
  margin-bottom: 3px;
  border: 1px solid var(--neutral-20);

  &:after {
    content: '';
    position: absolute;
    width: 29px;
    height: 29px;
    top: -3px;
    left: -3px;
    border-radius: inherit;
    border: 1px solid var(--neutral-20);
  }
}

.color {
  .variantselector__item:first-child {
    margin-left: 5px;
  }

  .variantselector__item {
    margin: 10px 12px 10px 0px;
    border: none;

    &.is-active {
      outline: 2px solid var(--blue);
      border-radius: 50%;
      outline-offset: 2px;

      &:after {
        content: '\2713';
        position: absolute;
        top: 0;
        left: 25%;
        color: var(--pure-white);
      }
    }

    &:last-of-type {
      margin-right: 0;
    }

    .variantselector__color {
      margin-right: unset;
      margin-left: unset;
      margin-bottom: unset;
      height: 24px;
      width: 24px;

      &:after {
        position: unset;
        top: unset;
        left: unset;
        border-radius: unset;
        border: unset;
      }
    }
  }
}

.variantselector__size {
  padding: 0 12px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  // border: 2px solid transparent;
  cursor: pointer;
}

.variantselector__product {
  width: 100%;
  height: auto;

  .variantselector__product__img {
    background-color: #F3F3F3;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin-bottom: 5px;
    border: 2px solid transparent;

    img {
      max-height: 100%;
      flex: 0.1;
      object-fit: contain;
      flex-grow: 1;
      width: auto;
    }
  }

  .variantselector__product__info {
    display: flex;
    flex-direction: column;
    font-size: 1.1rem;
  }
}

.variantselector__label {
  width: auto;
}
