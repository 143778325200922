@import 'styles/_sass-includes.scss';
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Inter:wght@400;500&display=swap');

body.checkout {
  .header-notification,
  #liveagent_invite_button_5733600000007Fu {
    display: none;
  }
}

.checkout__loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 64px);
}

.checkout__wrapper {
  @extend %container;
  @extend %page-margins;
  max-width: 1180px;
}

.checkoutpage {
  background-color: var(--neutral-05);
  min-height: 100vh;
  font-family: 'tt_commons', sans-serif;
  font-weight: 400;
  color: var(--neutral-70);
  font-size: 14px;

  h1, h2, h3, h4, h5, h6 {
    color: var(--neutral-90);
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    text-transform: none;
    letter-spacing: normal;
  }

  h2, .typ--h2 {
    font-size: 24px;
  }

  .typ--bold, strong {
    color: var(--neutral-100);
    font-weight: 500;
  }

  button {
    font-family: 'tt_commons', sans-serif;
  }
}

.checkout__inner {
  display: flex;
  padding: 40px 0 64px;
  min-height: calc(100vh - 64px);
  display: flex;
  column-gap: 32px;
  // media queries
  @include breakpoint($mobile-lg) {
    flex-direction: column;
    flex-direction: column-reverse;
  }
  @include breakpoint($mobile-lg) {
    min-height: calc(100vh - 136px);
  }

  .btn--placeorder {
    width: 100%;
    text-align: center;
    font-size: 26px;
    height: 72px;
  }

  .payment-page {
    input[type=submit],
    button[type=submit] {
      font-weight: 500;
      padding: 23px 18px;
      height: 55px;
      border-radius: 16px;
      // media queries
      @include breakpoint($mobile-lg) {
        width: 100%;
      }
    }
  }

  .pr_form__error {
    font-size: 12px;
    margin-top: 1px;
  }

  .pr_loaderbutton.pr_loading
  .pr_icon.pr_loading__icon {
    width: 1em;
    height: 1em;
  }
}

.checkout__aside {
  background-color: transparent;
  flex: 1;
  flex-grow: 0;
  margin-top: -24px;
  flex-basis: 372px;
  // media queries
  @include breakpoint($tablet-lg) {
    padding: 24px 0;
    margin-bottom: 32px;
  }
  @include breakpoint($mobile-lg) {
    padding: 16px 0;
    margin-bottom: 32px;
  }

  h3 {
    text-transform: unset;
  }
}

.pr_selectfield {
  padding-left: 12px;
  padding-right: 12px;
  font-size: 16px;
}

.checkout__main {
  flex: 1;
  width: 100%;

  > h2 {
    // media queries
    @include breakpoint($mobile-lg) {
      text-align: center;
      font-size: 18px;
    }
  }

  .pr_form__label {
    text-transform: unset;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 25px;
    color: $darkgrey;
  }
}

.checkout__maininner {
  max-width: 67rem;
  padding: 24px 40px;
  margin-left: auto;
  // media queries
  @include breakpoint($tablet-lg) {
    max-width: 100%;
    margin: 0 auto;
  }
  @include breakpoint($mobile-lg) {
    padding: 24px;
  }
}
.checkout-details__form .pr_inputgroup:not(:first-of-type) .pr_form__label.form__label {
  margin-top: 20px;
}

.checkout .btn--placeorder {
  margin-top: 50px;
}

.pr_inputgroup > .col--6 {
  width: calc(100% * 6 / 12 - 12px * (1 - 6 / 12));
  margin-right: 12px;
}

.checkout h2 {
  text-transform: unset;
  margin-bottom: 24px;
}

.checkout__paymentinfo {
  border: 1px solid #EAEAEA;
  padding: 10px 20px 26px;

  .afterpay-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;

    .AfterpayMessage {
      font-size: 14px;
      line-height: 0 !important;
      margin-top: 7px !important;
      margin-bottom: 0 !important;
    }

    .AfterpayMessage-logo {
      position: relative;
      top: -1px;
      left: 4px;

      svg {
        display: none;
      }
    }

    .pr_input__radio  {
      margin-bottom: 6px !important;
    }

    .pr_input__radio .pr_input__mark {
      top: 5px;
    }

    & .pr_input__mark {
      margin-bottom: 0;
    }
  }

  .success {
    color: $darkgreen;
  }

  .error {
    color: $red;
  }
}

.payment__tooltip.align-right {
  .pr_tooltip__toggle {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .pr_icon {
      margin-right: 7px;
    }

    h4 {
      margin-bottom: 0;
    }
  }

  .pr_tooltip__content {
    background-color: #BBBBBB;
    color: $white;
    font-size: 14px;
    padding: 5px 10px;

    &:before {
      display: none;
    }
  }
}

.checkout__legalize {
  font-size: 14px;
  color: #6a6f72;
  line-height: 1.712;
}

.checkout__optin {
  display: flex;
}

.checkoutsection {
  border-radius: 12px;
  background-color: var(--neutral-00);
  border: 1px solid var(--neutral-20);
  padding: 0 24px;
  position: relative;
  z-index: 2;
  // media queries
  @include breakpoint($mobile-md) {
    width: calc(100% + 48px);
    margin-left: -24px;
    border-radius: 0;
    border: 0;
    box-shadow: none;
  }
  @include breakpoint($mobile-sm) {
    width: calc(100% + 32px);
    padding: 0 16px;
    margin-left: -16px;
  }

  &.is-active {
    border-color: transparent;
    box-shadow:
      0px 4px 22px rgba(0, 0, 0, 0.05),
      0px 2.59259px 12.8843px rgba(0, 0, 0, 0.037963),
      0px 1.54074px 7.00741px rgba(0, 0, 0, 0.0303704),
      0px 0.8px 3.575px rgba(0, 0, 0, 0.025),
      0px 0.325926px 1.79259px rgba(0, 0, 0, 0.0196296),
      0px 0.0740741px 0.865741px rgba(0, 0, 0, 0.012037);
  }

  &:not(.last) {
    margin-bottom: 32px;

    &:after {
      content: '';
      position: absolute;
      left: 26px;
      bottom: -32px;
      height: 32px;
      z-index: 1;
      border-left: 2px dashed var(--neutral-30);
      // media queries
      @include breakpoint($mobile-md) {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.checkoutsection__inner {
  padding-bottom: 24px;
}

.checkoutsection__heading {
  display: flex;
  height: 60px;
  align-items: center;
  position: relative;

  &.is-active {
    margin-bottom: 24px;
  }

  h3 {
    font-size: 16px;
    text-transform: none;
  }

  span {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--neutral-20);
    width: 32px;
    height: 32px;
    margin-right: 12px;
    // media queries
    @include breakpoint($mobile-lg) {
      color: var(--primary-ui);
      font-size: 14px;
      width: 24px;
      height: 24px;
    }
  }
}

.checkoutsection.is-active .checkoutsection__heading {
  margin-bottom: 24px;
  height: 80px;
  // media queries
  @include breakpoint($mobile-lg) {
    height: 72px;
  }

  &:after {
    content: '';
    height: 1px;
    position: absolute;
    bottom: 0;
    width: calc(100% + 48px);
    left: -24px;
    background-color: var(--neutral-10);
    // media queries
    @include breakpoint($mobile-sm) {
      width: calc(100% + 32px);
      left: -16px;
    }
  }
}

.mobilecartsummary__toggle {
  @extend %page-margins;
  display: flex;
  align-items: center;
  height: 72px;
  justify-content: space-between;
  width: 100%;
  color: var(--neutral-100);
  background-color: var(--neutral-00);
  position: sticky;
  top: 0;
  z-index: 1002;
  box-shadow:
    0px 4px 22px rgba(0, 0, 0, 0.05),
    0px 2.59259px 12.8843px rgba(0, 0, 0, 0.037963),
    0px 1.54074px 7.00741px rgba(0, 0, 0, 0.0303704),
    0px 0.8px 3.575px rgba(0, 0, 0, 0.025),
    0px 0.325926px 1.79259px rgba(0, 0, 0, 0.0196296),
    0px 0.0740741px 0.865741px rgba(0, 0, 0, 0.012037);
}

.mobilecartsummary__toggle__price {
  display: flex;
  align-items: center;

  .pr_icon {
    margin-left: 7px;
    color: var(--primary-ui);
  }
}

.mobilecartsummary__toggle__cartlineitems {
  font-weight: 500;
  color: var(--neutral-100);
}

.pr_form__error_box{
  box-sizing: border-box;
    display: flex;
    align-items: center;
    padding-left: 12px;
    gap: 4px;
    height: 68px;
    background: linear-gradient(0deg, rgba(223, 4, 3, 0.05), rgba(223, 4, 3, 0.05)), #FFFFFF;
    border: 1px solid #DF0403;
    border-radius: 8px;
    margin-bottom: 15px;
}

.info_icon{
  stroke: none !important;
}
